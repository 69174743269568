<template>
    <div class="horse-detail">
        <head-img desc="马场分布" :imgUrl="horseBgUrl"></head-img>
        <my-menu :titleList="titleList" :menuList="menuList" @changeTab="changeTab"></my-menu>
        <div class="detail-content">
            <div class="content">
                <div class="content-top">
                    <div class="left">
                        <div class="bag-img">
                            <el-image class="detail-img" :src="getImg(information.picUrl)" fit="cover"></el-image>
                        </div>
                        <!-- <el-carousel type="card" height="150px" arrow="always" :autoplay="false" @change="changeImg">
                            <el-carousel-item v-for="item in detailImg" :key="item.url">
                                <el-image class="min-img" :src="item.url" fit="cover"></el-image>
                            </el-carousel-item>
                        </el-carousel> -->
                    </div>
                     <div class="right">
                        <div class="horse-name">
                            {{information.corpName}}
                        </div>
                        <div class="text">
                            <div class="title">经营介绍 :</div>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{information.businessIntroduction}}</p>
                        </div>
                        <div class="text">
                            <div class="title">场地介绍 :</div>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{information.siteIntroduction}}</p>
                        </div>

                        <div class="address">
                            <i class="el-icon-location"></i>
                            <span>{{information.address}}</span>
                        </div>
                        <!-- <div class="btn">
                            <span>质询我们</span>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="content-desc">
                    <div class="top">
                        <div class="btn">
                           马场详情
                        </div>
                    </div>
                    <div class="text">
                        <p>
                            马场详情内容描述，这里可以添加图片，需要自行排版，确认风格 
                            文章可以分为多段，中间可以插入图片，通过富文本框发布马场详细信息与风貌图片。
                            也可以增加链接，链接到其他站点进行引。
                        </p>
                    </div>
                    <div class="img-list">
                        <el-image v-for="(item, i) in detailImgList" :key="i" class="horse-img" :src="item.url" fit="cover"></el-image>
                    </div>
                    <div class="img-bottom">
                        <el-image class="horse-img-bottom" :src="bottomImgUrl" fit="cover"></el-image>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            horseBgUrl: require('@/assets/img/home/lunbo7.jpg'),
            bagImgUrl: require('@/assets/img/detail/detail1.jpeg'),
            bottomImgUrl: require('@/assets/img/detail/detailBottom.jpg'),
            detailImg: [
                {url: require('@/assets/img/detail/detail1.jpeg')},
                {url: require('@/assets/img/detail/detail2.jpeg')},
                {url: require('@/assets/img/detail/detail3.jpeg')},
                {url: require('@/assets/img/detail/detail4.jpeg')},
            ],
            detailImgList: [
                {url: require('@/assets/img/detail/detail5.jpeg')},
                {url: require('@/assets/img/detail/detail2.jpeg')},
                {url: require('@/assets/img/detail/detail3.jpeg')},
                {url: require('@/assets/img/detail/detail4.jpeg')},

            ],
            menuList: [
                {name: '首页', path: '/'},
                {name: '马场分布', path: '/horse'},
                {name: '一号马场'},
            ],
            titleList: [
                // {name: '一号马场'},
                // {name: '二号马场'},
                // {name: '三号马场'},
                // {name: '四号马场'},
            ],
            information: {}
        }
    },
    watch:{
        $route(to){
            console.log(to.path)
            if(to.path === '/horse/detail'){
                console.log(this.$route.query.id)
                this.id = this.$route.query.id
                this.getDetail()
            }
        }
    },
    methods:{
        changeImg(index) {
            console.log(index)
            this.bagImgUrl = this.detailImg[index].url
        },
        changeTab(data) {
            console.log(data)
            this.menuList[2].name = this.titleList[data].name 
        },
        getDetail() {
            this.$store.commit('loading', '.horse-detail .detail-content')
            this.$api.post('base/corp/corp-showForHomePage', this.$route.query).then(res=>{
                console.log(res)
                this.information = res.data
            })
            .finally(()=>{
                this.$store.commit('loaded')
            })
        },
    },
    created() {
        this.getDetail()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .horse-detail{
        .detail-content{
            display: flex;
            justify-content: center;
            align-items: center;
            .content{
                width: 1200px;
                margin: 50px 0;
                &-top{
                    display: flex;
                    .left{
                        .bag-img{
                            width: 600px;
                        }
                        .detail-img{
                            width: 100%;
                            height: 100%;
                        }
                        .el-carousel__indicators{
                            display: none;
                        }
                        
                    }
                    .right{
                        padding: 30px 10px 10px 30px;
                        .horse-name{
                            font-size: 32px;
                            color: #333;
                            margin-bottom:  10px;
                        }
                        .address{
                            margin-top: 10px;
                        }
                        .btn{
                            margin-top: 30px;
                            span{
                                padding: 7px 30px;
                                font-size: 16px;
                                color: #fff;
                                background: #c61c14
                            }
                        }
                    }
                }
                &-desc{
                    margin-top: 50px;
                    margin-bottom: 20px;
                    .top{
                        border-bottom: 1px solid #ccc;
                        .btn{
                            line-height: 50px;
                            text-align: center;
                            color: #FFF;
                            background: #1890ff;
                            border-bottom: 2px solid #1890ff;
                            width: 140px;

                        }
                    }
                    .text{
                        padding: 40px;
                        width: 500px;
                    }
                    .img-list{
                        padding: 0 100px;
                        display: flex;
                        justify-content: space-between;
                        //align-items: center;
                        flex-wrap: wrap;
                        .horse-img{
                            margin-bottom: 20px;
                        }

                    }
                    .img-bottom{
                        padding: 0 100px;
                    }
                }
            }
        }
    }

</style>